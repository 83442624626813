main {
    display: flex;
    overflow-x: scroll;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 42px;
    padding-bottom: 28px;
    padding-left: 23px;
    padding-right: 23px;
}

main .content h2 {
    font-weight: 700;
    color: #07070c;
    margin-bottom: 13px;
    font-size: 28px;
    line-height:35px;
}

main .content .breadcrumbs {
    display: flex;
    color: #6B7280;
    font-size: 0.75rem;
    line-height: 1rem;
    align-items: center;
    margin-bottom: 37px;
    column-gap:11px;
}

main .content .breadcrumbs .breadcrumb-items {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
}