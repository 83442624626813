a {
    text-decoration: none;
}

.logo {
    position: relative;
}

.logo img {

    height: auto;
  
    max-width: 100%;
  
  }

.sidebar-maximized {
    background-color: #FFFFFF;
    grid-row: span 2 / span 2;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: #e8edf2;
    position: relative; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    padding: 25px;
    width: 260px;
    min-height: 100vh;
}

.sidebar-minimized {
    background-color: #FFFFFF;
    grid-row: span 2 / span 2;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: #e8edf2;
    position: relative; 
    display: flex; 
    flex-direction: row; 
    justify-content: center;
    width: 85px;
    min-height: 100vh;
    padding: 15px;
}

.sidebar-minimized .sidebar-items {

}



.sidebar-maximized .sidebar-items .sidebar-navigation {
    padding-top: 25px;
    padding-bottom: 18px;
}

.sidebar-minimized .sidebar-items .sidebar-navigation {
    padding-top: 25px;
    padding-bottom: 18px;
}


.sidebar-maximized .sidebar-button {
position: absolute;
margin-top: 20px;
padding: 0.5rem;
background-color: #ffffff;
border-color: #e8edf2;
border-radius: 9999px; 
right: 0;
border-width: 1px;
cursor: pointer; 
transition-duration: 300ms; 
transform: translateX(50%); 
}

.sidebar-minimized .sidebar-button {
    position: absolute;
    margin-top: 20px;
    padding: 0.5rem;
    background-color: #ffffff;
    border-color: #e8edf2;
    border-radius: 9999px; 
    right: 0;
    border-width: 1px;
    cursor: pointer; 
    transition-duration: 300ms; 
    transform: rotate(180deg) translateX(-50%);
}


.sidebar-items .sidebar-navigation .sidebar-navigation-item {
    position: relative; 
    border-radius: 0.75rem;
    color: #6B7280;
}

.sidebar-items .sidebar-navigation .sidebar-navigation-item:focus-within {
    background-color: #2775ff;
    color: #ffffff;
}


.sidebar-items .sidebar-navigation .sidebar-navigation-item input[type="checkbox"] {
    clip:rect(0,0,0,0);
    border-width:0;
    width:1px;
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    white-space:nowrap;
}

.sidebar-items .sidebar-navigation .sidebar-navigation-item input[type="checkbox"] ~ * ul {
    display: none;
}

.sidebar-items .sidebar-navigation .sidebar-navigation-item input[type="checkbox"]:checked ~ * ul {
    display: block;
}


#submissions-checkbox:checked ~ #submissions-toggle {
    transform: rotate(180deg);
}

.sidebar-maximized .sidebar-items .sidebar-navigation .sidebar-navigation-item label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding-bottom:17px;
    padding-top:17px;
    padding-left:21px;
    padding-right:21px;
    outline:2px solid transparent;
    outline-offset:2px;
        /* border-color:transparent on checkbox */
}

.sidebar-minimized .sidebar-items .sidebar-navigation .sidebar-navigation-item label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding-bottom:17px;
    padding-top:17px;
    outline:2px solid transparent;
    outline-offset:2px;
        /* border-color:transparent on checkbox */
}

.sidebar-maximized .sidebar-items .sidebar-navigation .sidebar-navigation-item label .label-items {
    display: flex;
    align-items: center;
    gap: 10px;
}
.sidebar-maximized .sidebar-items .sidebar-navigation .sidebar-navigation-item label .label-items svg {
    color: inherit;
} 


.sidebar-maximized .sidebar-items .sidebar-navigation .sidebar-navigation-item label .label-items span {
    color: inherit;
    font-weight: 600;
    font-size:14px;
    line-height:16px;
}

.sidebar-minimized .sidebar-items .sidebar-navigation .sidebar-navigation-item label .label-items span {
    display: none;
}

.sidebar-maximized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown-toggle {
    position: absolute; 
    right: 0.5rem; 
    pointer-events: none; 
    top: 22px;
}

.sidebar-minimized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown-toggle {
    display: none;
}

.sidebar-maximized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown ul {
    margin: 0px;
    padding-left: 53px;
    list-style: none;
}

.sidebar-minimized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown ul {
    position: absolute;
    top: 0px;
    left: 115%;
    list-style: none;
    background-color: #f5f5fa;
    border-radius: 8px;
    border: 2px solid #ffffff;
    min-width: 100px;
    padding: 20px;
}

.sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown ul li {
    padding-bottom: 0.5rem;
    transition-property: opacity;
    transition-duration: 150ms;
    
}

.sidebar-maxmized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown ul li:hover {
    opacity: 0.75; 
}

.sidebar-minimized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown ul li:hover {
    opacity: 0.75; 
}

.sidebar-maximized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown ul li a {
    font-size:14px;
    line-height:16px;
    color: inherit;
}
.sidebar-minimized .sidebar-items .sidebar-navigation .sidebar-navigation-item .dropdown ul li a {
    font-size:14px;
    line-height:16px;
    color: grey;
}

.sidebar-navigation-item .dropdown ul li a {
    font-size:14px;
    line-height:16px;
    color: grey;
}