header {
    background-color: #ffffff;
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    height: 115px;
    flex-wrap: wrap; 
    justify-content: space-between;
    align-items: center; 
    line-height: 1.5rem;
    gap: 0;
    width: auto;
}

header .icons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

header .icons label {
    padding: 5px;
    color: #6B7280;
    cursor: pointer;
    
}
