@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

#root {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

* {
    margin: 0;
    padding: 0;
}


.dashboard {
    overflow-x: hidden;
    position: relative;
    background-color: #F3F4F6;
    min-height: 100vh;
    width: 100vw;
    line-height: inherit;
    margin: 0;
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
}

.layout {
    display: grid;
    color: #111827; 
    font-weight: 400;
    margin-left:auto;
    margin-right:auto;
    grid-template-rows: auto 1fr;
    /* grid-template-columns: 85px 1fr; */
    grid-template-columns: auto 1fr;
    -ms-overflow-style: none;
    scrollbar-width: none
}


.dropdown ul {
  z-index: 1;
}

.sidebar {
    background-color: #FFFFFF;
    grid-row: span 2 / span 2;
    border-right-width: 1px;
    border-color: #e8edf2;
    position: relative; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    padding: 25px;
}

.minimize {
    grid-template-columns: 117px 1fr;
}


.content-container {
    background-color: #FFF;
     padding: 30px;
     border-radius: 24px;
     margin-bottom: 10px;
}

.data-label {
    color: #6B7280;
    font-size: .75rem;
    font-weight: bold;
}

.data-value {
    padding: 0 0 10px 10px;
}



.data-select {
    margin: 5px;
    padding: 7px;
    width: 225px;
    border: none;
    background: transparent;
    box-sizing: border-box;
    background-color: rgba(126, 126, 126, 0.1);
    color: rgb(0, 0, 0, 0.8);
    box-shadow: 2px 2px 5px 1px #bdbdbd;
    font-size: .9rem;
}

.data-select option {
	height: 20px;
	cursor: pointer;
}

.label-focus-container {
  margin: 10px;

}

.label-focus {
  padding: 7px;
  color: rgb(0, 0, 0, 0.8);
  border: none;
  background: transparent;
  box-sizing: border-box;
  background-color: rgba(126, 126, 126, 0.1);
  box-shadow: 2px 2px 5px 1px #bdbdbd;
  font-size: .9rem;
  width: 225px;
}

.text-input-container {
    margin: 5px;
}

.text-input {
    padding: 7px;
    color: rgb(0, 0, 0, 0.8);
    border: none;
    background: transparent;
    box-sizing: border-box;
    background-color: rgba(126, 126, 126, 0.1);
    box-shadow: 2px 2px 5px 1px #bdbdbd;
    font-size: .9rem;
    width: 225px;
}

.text-input:active,
.text-input:focus {
    outline: none;
    border: none;
    box-shadow: 2px 2px 5px 2px #111827;
}

.switch {
    margin: 5px;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(194, 35, 35);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #04c914;
  content: "";
}

input:focus + .slider {
  box-shadow: 0 0 1px #04c914;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.submit-button {
  margin: 5px;
  margin-top: 25px;
  padding: 5px;
  color: rgb(0, 0, 0, 0.8);
  border: none;
  background: transparent;
  box-sizing: border-box;
  background-color: rgba(126, 126, 126, 0.1);
  box-shadow: 2px 2px 5px 1px #bdbdbd;
  font-size: 1rem;
  width: 225px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: rgba(126, 126, 126, 0.2);
}

.submit-button:active {
      background-color: rgba(126, 126, 126, 0.4);
}


.register-container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F3F4F6;
}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 650px;
  width: 500px;
  background-color: #FFF;
  border-radius: 24px;
  box-shadow: 2px 2px 5px 1px #bdbdbd;
}


.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F3F4F6;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 500px;
    background-color: #FFF;
    border-radius: 24px;
    box-shadow: 2px 2px 5px 1px #bdbdbd;
}

.padding-5 {
    padding: 5px;
}

.width-full {
    width: 100%;
}

.height-full {
    height: 100%;
}

.login-logo {
  margin: 10px;
}

@media (max-width: 400px) {
  .login-form {
    width: 300px;
    height: 400px;
  }

  .register-form {
    width: 300px;
    height: 550px;
  }
}


@media(min-width: 401px) and (max-width: 600px) {
  .login-form {
    width: 400px;
    height: 500px;
  }

  .register-form {
    width: 400px;
    height: 550px;
  }
}